<template>
  <div>
    <v-main>
      <component
        v-bind="userInfo"
        @done="go"
        :flag="flag"
        :is="page[currentPage]"
      />
    </v-main>
    <Footer :currentPage="currentPage" @next="next" @back="back" />
  </div>
</template>
<script>
import Api from "../services";
import Page1 from "./Page-1";
import Page2 from "./Page-2";
import Page3 from "./Page-3";
import Page4 from "./Page-4";
import Page5 from "./Page-5";
import Page6 from "./Page-6";
import Footer from "../components/footer";
export default {
  name: "App",
  components: {
    Page1,
    Page2,
    Page3,
    Page4,
    Page5,
    Page6,
    Footer,
  },
  data: () => ({
    userInfo: {},
    flag: false,
    currentPage: 0,
    nextPage: 0,

    page: ["Page1", "Page2", "Page3", "Page4", "Page5", "Page6"],
  }),
  methods: {
    next() {
      if (this.nextPage < this.page.length - 1 && this.nextPage > -1) {
        this.flag = !this.flag;
        this.nextPage++;
      }
    },
    back() {
      if (this.nextPage < this.page.length && this.nextPage > 0) {
        this.flag = !this.flag;
        this.nextPage--;
      }
    },
    go() {
      this.currentPage = this.nextPage;
    },
    end() {
      this.nextPage = 0;
    },
  },
  created() {
    Api.getUserInfo(this.$route.query.token)
      .then((res) => {
        this.userInfo = res;
      })
      .catch(() => {
        this.$router.push({
          path: "/er",
        });
      });
  },
};
</script>
