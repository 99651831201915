<template>
  <v-expand-transition>
    <div class="page-container" v-show="expand">
      <img class="image__background" src="../assets/m3.png" alt="" />
      <div class="page__inner">
        <div class="inner-content">
          <div v-for="i in 5" :key="i" class="list">
            <Card :image="i * 2 - 1" :time="(i * 2 - 1) * 300" />
            <Card :image="i * 2" :time="i * 2 * 300" />
          </div>
        </div>
      </div>
    </div>
  </v-expand-transition>
</template>
<script>
import Card from "../components/m3-card";
export default {
  components: {
    Card,
  },
  props: {
    flag: {
      type: Boolean,
    },
  },
  data() {
    return {
      expand: false,
    };
  },
  watch: {
    flag: function () {
      setTimeout(() => {
        this.expand = false;
      }, 600);
      setTimeout(() => {
        this.$emit("done");
      }, 700);
    },
  },
  mounted() {
    setTimeout(() => {
      this.expand = true;
      setTimeout(() => {
        this.show = true;
      }, 700);
    }, 300);
  },
};
</script>
<style lang="scss" scoped>
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.page-container {
  position: relative;
  text-align: center;
  color: white;
  .image__background {
    max-width: 100%;
  }
  .page__inner {
    position: absolute;
    top: 29%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .inner-content {
      width: 95%;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;
      .list {
        display: flex;
        justify-content: space-around;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
