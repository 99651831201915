<template>
  <v-footer fixed padless class="page-footer" color="rgba(255, 255, 255, 1)">
    <div class="footer-inner">
      <div>
        <img src="../assets/next.png" class="back-button" @click="back" v-show="currentPage!=0"/>
      </div>
      <div>
        <img src="../assets/next.png" class="next-button" @click="next" v-show="currentPage!=5" />
      </div>
    </div>
  </v-footer>
</template>
<script>
export default {
  props: {
    currentPage: {
      type: Number,
    }
  },
  methods: {
    back() {
      this.$emit("back");
    },
    next() {
      this.$emit("next");
    },
  },
};
</script>
<style lang="scss" scoped>
.page-footer {
  background: none !important;
  .footer-inner {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: none;
    .back-button {
      width: 50px;
      margin: 0 0 15px 20px;
      transform: rotate(180deg);
    }
    .next-button {
      width: 50px;
      margin: 0 20px 15px 0;
    }
  }
}
</style>
