<template>
  <transition name="bounce">
    <div class="wrapper" v-if="showWraper">
      <transition name="bounce">
        <img
          v-if="show"
          class="list__item"
          :src="require(`../assets/m3-${image}.png`)"
        />
      </transition>
    </div>
  </transition>
</template>
<script>
export default {
  props: {
    image: {
      type: Number,
    },
    time: {
      type: Number,
    },
  },
  data() {
    return {
      showWraper: false,
      show: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.showWraper = true;
      setTimeout(() => {
        this.show = true;
      }, 700);
    }, 1000 + this.time);
  },
};
</script>
<style lang="scss" scoped>
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
.wrapper {
  width: 45%;
}
.list__item {
  max-width: 100%;
}
</style>