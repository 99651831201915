<template>
  <v-expand-transition>
    <div class="page-container" v-show="expandBackground">
      <img class="image__background" src="../assets/m1.png" />
      <v-expand-transition>
        <div class="page__inner" v-show="expandText">
          <div class="text-font--normal">Xin chào</div>
          <div class="text-font--normal text--upper margin-bottom">{{ name }}</div>
          <div class="text-font--normal">{{ text }}</div>
        </div>
      </v-expand-transition>
    </div>
  </v-expand-transition>
</template>
<style lang="scss" scoped>
.page-container {
  position: relative;
  text-align: center;
  color: white;
  .image__background {
    max-width: 100%;
  }
  .page__inner {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    align-items: center;
    color: white;
    text-align: center;
    width: 90%;
    margin-top: 20px;
    .text-font {
      &--normal {
        font-size: 0.8rem;
        font-weight: 500;
      }
    }
    .text--upper {
      text-transform: uppercase;
    }
    .margin-bottom {
      margin-bottom: 5px;
    }
  }
}
</style>

<script>
export default {
  props: {
    name: {
      type: String,
      default: "Bạn",
    },
    flag: {
      type: Boolean,
    },
  },
  data() {
    return {
      expandBackground: false,
      expandText: false,
      text:
        "Chắc hẳn 2020 là một năm đáng nhớ với rất nhiều kỷ niệm cho bạn và cho cả AhaMove. Những điều thú vị sau thay lời cảm ơn AhaMove dành cho những đóng góp của bạn trong hành trình vừa qua. Hãy cùng đồng hành với AhaMove và…",
    };
  },
  watch: {
    flag: function () {
      this.expandText = false;
      setTimeout(() => {
        this.expandBackground = false;
      }, 300);
      setTimeout(() => {
        this.$emit("done");
      }, 500);
    },
  },
  mounted() {
    setTimeout(() => {
      this.expandBackground = true;
      setTimeout(() => {
        this.expandText = true;
      }, 500);
    }, 300);
  },
};
</script>