<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>
<style lang="scss" scoped>
* {
  font-family: WinSumFont, MacSumFont, Arial;
}
</style>
<script>
export default {
  name: "App",
};
</script>
