<template>
  <v-expand-transition>
  <div v-show="expand">
    <img class="image__background" src="../assets/m5.png" alt="">
  </div>
  </v-expand-transition>
</template>
<style lang="scss" scoped>
.image__background {
  max-width: 100%;
}
</style>
<script>
export default {
  props: {
    flag: {
      type: Boolean,
    },
  },
  data() {
    return {
      expand: false,
    }
  },
  watch: {
    flag: function () {
      this.expand = false;
      setTimeout(() => {
        this.$emit("done")
      }, 500);
    },
  },
  mounted() {
    setTimeout(() => {
      this.expand = true;
    }, 300);
  },
}
</script>
