<template>
  <transition name="bounce">
    <div class="item-container" v-if="show">
      <img class="image" :src="require(`../assets/m4-${image}.png`)" />
      <div class="item__text">{{ animatedNumber }}</div>
    </div>
  </transition>
</template>
<script>
import gsap from "gsap";
export default {
  props: {
    image: {
      type: Number,
    },
    value: {
      type: Number,
    },
    time: {
      type: Number,
    },
  },
  data() {
    return {
      show: false,
      number: 0,
      tweenedNumber: 0,
    };
  },
  computed: {
    animatedNumber() {
      return this.numberWithCommas(this.tweenedNumber.toFixed(0));
    },
  },
  methods: {
    numberWithCommas(x) {
      return parseInt(x).toLocaleString()
    },
  },
  watch: {
    number(newValue) {
      gsap.to(this.$data, { duration: 0.5, tweenedNumber: newValue });
    },
  },
  mounted() {
    setTimeout(() => {
      this.show = true;
    }, 1000 + this.time);
    setTimeout(() => {
      this.number = this.value;
    }, 1000 + this.time + 3000);
  },
};
</script>
<style lang="scss" scoped>
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
@mixin parentGround {
  position: relative;
  text-align: center;
}

.item-container {
  @include parentGround();
  .image {
    max-width: 100%;
  }
  .item__text {
    padding-right: 10px;
    position: absolute;
    top: 40%;
    left: 90%;
    transform: translate(-50%, -50%);
  }
}
</style>
