<template>
  <v-expand-transition>
    <div class="page-container" v-show="expand">
      <img class="image__background" src="../assets/m4.png" />
      <div class="page__inner">
        <transition name="bounce">
          <div class="inner" v-if="show">
            <div class="inner__top">{{ thu_hang_ve_doanh_thu }}</div>
            <div class="inner__middle">
              <Card
                v-for="(v, i) in info.slice(0, 6)"
                :key="i"
                :image="i + 1"
                :value="v"
                :time="i * 500"
              />
            </div>
            <transition name="bounce">
              <div class="inner__bottom" v-if="showBottom">
                <img class="bottom__image" src="../assets/m4-doanh thu.png" />
                <div class="bottom__text">
                  <div class="text__top">TỔNG DOANH THU</div>
                  <div class="text__middle">{{ animatedNumber }}</div>
                  <div class="text__bottom">VNĐ</div>
                </div>
              </div>
            </transition>
          </div>
        </transition>
      </div>
    </div>
  </v-expand-transition>
</template>
<script>
import Card from "../components/m4-card";
import gsap from "gsap";
export default {
  components: {
    Card,
  },
  props: {
    auto_assign: {
      type: Number,
      default: 0,
    },
    doanh_thu: {
      type: Number,
      default: 0,
    },
    so_diem_giao_thanh_cong: {
      type: Number,
      default: 0,
    },
    so_don_5_sao: {
      type: Number,
      default: 0,
    },
    so_don_hoan_thanh: {
      type: Number,
      default: 0,
    },
    so_khach_hang_da_phuc_vu: {
      type: Number,
      default: 0,
    },
    so_ngay_hoat_dong: {
      type: Number,
      default: 0,
    },
    thu_hang_ve_doanh_thu: {
      type: String,
      default: "",
    },
    flag: {
      type: Boolean,
    },
  },
  data() {
    return {
      expand: false,
      show: false,
      showBottom: false,
      number: 0,
      tweenedNumber: 0,
    };
  },
  computed: {
    animatedNumber() {
      return this.numberWithCommas(this.tweenedNumber.toFixed(0));
    },
    info() {
      return [
        this.so_ngay_hoat_dong,
        this.so_khach_hang_da_phuc_vu,
        this.so_don_5_sao,
        this.so_don_hoan_thanh,
        this.auto_assign,
        this.so_diem_giao_thanh_cong,
        this.doanh_thu,
      ];
    },
  },
  watch: {
    number(newValue) {
      gsap.to(this.$data, { duration: 0.9, tweenedNumber: newValue });
    },
    flag: function () {
      this.show = false;
      setTimeout(() => {
        this.expand = false;
      }, 500);
      setTimeout(() => {
        this.$emit("done");
      }, 700);
    },
  },
  methods: {
    numberWithCommas(x) {
      return parseInt(x).toLocaleString()
    },
  },
  mounted() {
    setTimeout(() => {
      this.expand = true;
      setTimeout(() => {
        this.show = true;
        setTimeout(() => {
          this.showBottom = true;
          setTimeout(() => {
            this.number = this.info[6];
          }, 2700);
        }, 4500);
      }, 700);
    }, 300);
  },
};
</script>
<style lang="scss" scoped>
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
@mixin parentGround {
  position: relative;
  text-align: center;
}
.page-container {
  position: relative;
  text-align: center;
  color: white;
  .image__background {
    max-width: 100%;
  }
  .page__inner {
    position: absolute;
    top: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .inner {
      width: 90%;
      &__top {
        color: #f66f0f;
        font-size: 30px;
        font-weight: 500;
        margin-bottom: 20px;
      }
      &__middle {
        width: 100%;
        .image {
          max-width: 100%;
        }
        .item-container {
          @include parentGround();
          .item__text {
            position: absolute;
            top: 40%;
            left: 90%;
            transform: translate(-50%, -50%);
          }
        }
      }
      &__bottom {
        width: 100%;
        @include parentGround();
        .bottom__image {
          max-width: 100%;
        }
        .bottom__text {
          width: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          line-height: normal;
          .text {
            &__top {
              font-weight: 600;
              font-size: 20px;
            }
            &__middle {
              font-size: 45px;
              font-weight: 700;
            }
            &__bottom {
              font-weight: 600;
              font-size: 20px;
            }
          }
        }
      }
    }
    .image {
      max-width: 100%;
    }
  }
}
</style>
