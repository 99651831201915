<template>
  <v-expand-transition>
    <div class="page-container" v-show="expand">
      <img class="image__background" src="../assets/m2.png" alt="" />
      <div class="page__inner">
        <img class="image" src="../assets/tx.gif" alt="" />
      </div>
    </div>
  </v-expand-transition>
</template>
<script>
export default {
  props: {
    flag: {
      type: Boolean,
    },
  },
  data() {
    return {
      expand: false,
    };
  },
  watch: {
    flag: function () {
      this.expand = false;
      setTimeout(() => {
        this.$emit("done");
      }, 500);
    },
  },
  mounted() {
    setTimeout(() => {
      this.expand = true;
    }, 300);
  },
};
</script>
<style lang="scss" scoped>
.page-container {
  position: relative;
  text-align: center;
  color: white;
  .image__background {
    max-width: 100%;
  }
  .page__inner {
    position: absolute;
    bottom: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    margin-top: 20px;
    .image {
      max-width: 90%;
    }
  }
}
</style>