import axios from "axios";

const appApiInternal = axios.create({
  baseURL: `${process.env.VUE_APP_USER_API}`,
  timeout: 90000,
});

export default {
  async getUserInfo(token) {
    let { data } = await appApiInternal.get("init_summary", {
      params: {
        token: token,
      },
    }).then();
    return data;
  },
};
